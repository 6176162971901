<template>
  <div class="container pt-3">
    <h4 class="text-center">{{ title }}</h4>
    <p>{{ choice.title }}</p>
    <p>{{ choice.score }}</p>
    <div class="text-center">
      <router-link :to="{ name: choice.linkName }">
        <button type="button" class="btn btn-secondary px-5">เริ่ม</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RandomTemplate",
  data() {
    return {
      title: "เรียนรู้พาราย",
      choice: { title: "การตลาดฉบับโชห่วย", score: 170, linkName: "Intro" },
    };
  },
};
</script>