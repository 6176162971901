<template>
  <div class="history">
    <div class="box-title">
      <p
        v-if="!isLoading && (!historyPoint || historyPoint.length === 0)"
        class="text"
      >
        0
      </p>
      <p v-else class="text">
        {{ historyPoint[historyPoint.length - 1].score }}
      </p>
    </div>
    <div class="history-list">
      <img v-bind:src="imgCardBgGame" class="bg-card" />
      <h2 class="text-center text-white fw-bold">ประวัติการเล่น</h2>
      <div class="d-flex justify-content-between head-table">
        <div class="d-flex align-items-center">
          <img v-bind:src="imgCalendar" width="30" />
          <p class="mb-0 ms-2">วันที่/เวลา</p>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <img v-bind:src="imgCoin" width="20" />
          <p class="mb-0 ms-2">เหรียญ</p>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table align-middle">
          <tbody>
            <p v-if="isLoading">Loading...</p>
            <p v-else-if="!isLoading && error">{{ error }}</p>
            <p
              v-else-if="
                !isLoading && (!historyPoint || historyPoint.length === 0)
              "
            >
              ไม่มีจุดบันทึกประวัติ
            </p>
            <tr v-else v-for="(history, index) in historyPoint" :key="index">
              <td>{{ dateFormat(history.logdate) }}</td>

              <td>
                <div class="d-flex align-items-center justify-content-end">
                  <img v-bind:src="imgCoin" width="10" />
                  <p class="mb-0 ms-2">{{ history.score }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <a href="https://paruay.unileveractivity.com/Home/Profile">
        <img v-bind:src="imgBtn" class="btn-play" />
      </a>
    </div>
  </div>
</template>

<script>
const imgCoin = require("../assets/images/coin-u.png");
const imgCalendar = require("../assets/images/calendar.png");
const imgBtn = require("../assets/images/btn.png");
import axios from "axios";
const imgCardBgGame = require("../assets/images/card-table-game.png");

export default {
  name: "TableHistoryTemplate",
  data() {
    return {
      imgCoin: imgCoin,
      imgCalendar: imgCalendar,
      imgBtn: imgBtn,
      historyPoint: [],
      isLoading: false,
      error: null,
      imgCardBgGame: imgCardBgGame,
    };
  },
  methods: {
    async loadingPointLog() {
      try {
        this.isLoading = true;
        const respones = await axios.get(
          `${process.env.VUE_APP_BASE_API_URL}Plugin/getPluginPointLog?custUID=${this.$store.getters.getCustUID}`
        );
        const responData = await respones.data.data;

        this.historyPoint = responData;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        this.error = "ดึงข้อมูลไม่สำเร็จ - อย่าลืมลองอีกครั้ง";
      }
    },
  },
  computed: {
    dateFormat() {
      return (date) => new Date(date).toLocaleDateString("en-GB");
    },
  },
  mounted() {
    this.loadingPointLog();
  },
  created() {
    if (this.$route.query.custUID) {
      this.$store.commit("setCustUID", this.$route.query.custUID);
    }
  },
};
</script>
