<template>
  <div v-if="$route.params.id == 1">
    <CardDesign
      :title="'ยินดีด้วย'"
      :detail="'ตอบถูกทุกข้อ'"
      :btn="true"
      :linkName="content.linkName"
      :btnName="'ต่อไป'"
      :paramsID="1"
      :pSuccess="true"
    />
  </div>
  <div v-else-if="$route.params.id == 2">
    <CardDesign
      :title="'ยินดีด้วย'"
      :detail="'ตอบถูกทุกข้อ'"
      :btn="true"
      :linkName="content.linkName"
      :btnName="'ต่อไป'"
      :paramsID="2"
      :pSuccess="true"
    />
  </div>
  <div v-else-if="$route.params.id == 3">
    <CardDesign
      :title="'ยินดีด้วย'"
      :detail="'ตอบถูกทุกข้อ'"
      :btn="true"
      :linkName="content.linkName"
      :btnName="'ต่อไป'"
      :paramsID="3"
      :pSuccess="true"
    />
  </div>
  <div v-else-if="$route.params.id == 4">
    <CardDesign
      :title="'ยินดีด้วย'"
      :detail="'ตอบถูกทุกข้อ'"
      :btn="true"
      :linkName="content.linkName"
      :btnName="'ต่อไป'"
      :paramsID="4"
      :pSuccess="true"
    />
  </div>
</template>

<script>
import CardDesign from "../components/CardDesign.vue";
export default {
  name: "SuccessTemplate",
  data() {
    return {
      content: {
        detail: "ตอบถูกทุกข้อ",
        linkName: "Summary",
      },
    };
  },
  components: {
    CardDesign,
  },
};
</script>