<template>
  <div class="container pt-3">
    <div class="text-center position-relative h-100 w-100 card-design">
      <div class="card-main">
        <div class="card-main-sub">
          <img v-bind:src="img" class="logo-nav" />
          <div
            class="d-flex flex-column align-items-center justify-content-between mt-5 pt-3 content"
          >
            <div>
              <h5 class="text-title mt-4 mb-3">สรุป</h5>
              <img v-bind:src="imgHr" class="img-hr" />
              <div v-if="$route.params.id == 1">
                <h4 class="text-title-sub mt-2">การตลาดฉบับโชห่วย</h4>
                <ol>
                  <li
                    v-for="(item, index) in content"
                    :key="index"
                    class="text-start"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div v-else-if="$route.params.id == 2">
                <h4 class="text-title-sub mt-2">การจัดการสินค้าดียังไง</h4>
                <ol>
                  <li
                    v-for="(item, index) in content2"
                    :key="index"
                    class="text-start"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div v-else-if="$route.params.id == 3">
                <h4 class="text-title-sub mt-2">จัดเรียงสินค้ายังไงให้น่าซื้อ</h4>
                <ol>
                  <li
                    v-for="(item, index) in content3"
                    :key="index"
                    class="text-start"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div v-else>
                <h4 class="text-title-sub mt-2">โชห่วยยุค 4.0</h4>
                <ol>
                  <li
                    v-for="(item, index) in content4"
                    :key="index"
                    class="text-start"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
            </div>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-danger px-5 text-nowrap"
                @click="saveScore()"
              >
                ต่อไป
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const imgLink = require("../assets/images/logo.png");
const imgHr = require("../assets/images/hr.png");
const axios = require("axios");
export default {
  name: "SummaryTemplate",
  data() {
    return {
      img: imgLink,
      imgHr: imgHr,
      title: "หน้าสรุปเนื้อหา",
      content: [
        "เข้าร่วมโครงการส่งเสริมของรัฐบาล เช่น ธงฟ้าประชารัฐ",
        "มีช่องทางในการสื่อการกับลูกค้าโดยตรงผ่าน LINE",
        "มีกิจกรรมกระตุ้นยอดขายภายในร้าน",
        "มีโปรโมชั่นพิเศษ สำหรับลูกค้าที่ซื้อสินค้าจำนวนมาก"
      ],
      content2: [
        "จัดสัดส่วนสินค้าให้มีบนชั้นวาง 80% และสต็อค 20% ",
        "จัดสินค้าแบบเข้าก่อนขายก่อน (FIFO) ",
        "สถานที่จัดเก็บสินค้าจะต้องแยกประเภทสินค้า และน้ำหนักสินค้า",
        "ใช้ POS ในการเช็คยอดขาย และสต็อคสินค้าภายในร้านได้"
      ],
      content3: [
        "แบ่งหมวดหมู่สินค้า",
        "วางสินค้าขายดีระดับสายตา",
        "สินค้าชิ้นเล็กเรียงด้านบน ชิ้นใหญ่ไว้ด้านล่าง",
        "แยกสินค้าที่เป็นสารเคมีกับอาหารออกจากกัน"
      ],
      content4: [
        "S-Sustainability (การทำธุรกิจอย่างยั่งยืน)",
        "M-Manageable (เก่งการจัดการ)",
        "A-Adaptable (ปรับตัวว่องไว)",
        "R-Relationship (มีความสัมพันธ์ที่ดีเยี่ยมในชุมชน)",
        "T-Technology (ใช้เทคโนโลยีเข้ามาช่วยในการบริหารร้าน)"
      ]
    };
  },
  methods: {
    saveScore() {
      const data = {
        lessonCode: this.$store.getters.getLessonID,
        score: this.$store.getters.getScore,
        custUID: this.$store.getters.getCustUID,
      };
      axios
        .post(
          process.env.VUE_APP_BASE_API_URL + "Plugin/submitLessonPoint",
          data
        )
        .then((response) => {
          if (response.status) {
            if (response.status) {
              this.$router.push(`/reward`);
            }
          } else {
            alert(response.data);
          }
        })
        .catch((e) => {
          this.$router.push(`/reward`);
          //alert(e.response.data.data); // remove alert
          console.log(e.response.data.data);
        });
    },
  },
};
</script>