<template>
  <div v-if="$route.params.id == 1">
    <CardDesign
      :title="'การตลาดฉบับโชห่วย'"
      :subTitle="'วัตถุประสงค์'"
      :detail="content.detail"
      :btn="true"
      :linkName="content.linkName"
      :btnName="'เริ่ม'"
      :paramsID="1"
      :pImgTarot="true"
    />
  </div>
  <div v-else-if="$route.params.id == 2">
    <CardDesign
      :title="'การจัดการสินค้าดียังไง'"
      :subTitle="'วัตถุประสงค์'"
      :detail="content2.detail"
      :btn="true"
      :linkName="content2.linkName"
      :paramsID="2"
      :btnName="'เริ่ม'"
      :pImgTarot="true"
    />
  </div>
  <div v-else-if="$route.params.id == 3">
    <CardDesign
      :title="'จัดเรียงสินค้ายังไงให้น่าซื้อ'"
      :subTitle="'วัตถุประสงค์'"
      :detail="content3.detail"
      :btn="true"
      :linkName="content3.linkName"
      :paramsID="3"
      :btnName="'เริ่ม'"
      :pImgTarot="true"
    />
  </div>
  <div v-else>
    <CardDesign
      :title="'โชห่วยยุค 4.0'"
      :subTitle="'วัตถุประสงค์'"
      :detail="content4.detail"
      :btn="true"
      :linkName="content4.linkName"
      :paramsID="4"
      :btnName="'เริ่ม'"
      :pImgTarot="true"
    />
  </div>
</template>

<script>
import CardDesign from "../components/CardDesign.vue";

export default {
  name: "IntroTemplate",
  data() {
    return {
      content: {
        title: "สิ่งที่ได้จากการเรียนรู้",
        detail:
          "เพื่อให้รู้จักเครื่องมือทางการตลาด ที่ร้านโชห่วยสามารถน้ำไปประยุกต์ใช้กับร้านตัวเองได้",
        linkName: "ContentCard",
        paramsId: 1,
      },
      content2: {
        title: "สิ่งที่ได้จากการเรียนรู้",
        detail:
          "การจัดการบริหารสินค้าได้ดีจะทำให้ร้านโชห่วยมีเงินหมุนเวียน และลดต้นทุนด้วย",
        linkName: "ContentCard",
        paramsId: 2,
      }, 
      content3: {
        title: "จัดเรียงสินค้ายังไงให้น่าซื้อ",
        detail:
          "การจัดเรียงสินค้าเป็นอีกวิธีที่ช่วยร้านเพิ่มยอดขาย ร้านควรจัดเรียงสินค้ายังไงให้สินค้าน่าซื้อมากขึ้น",
        linkName: "ContentCard",
        paramsId: 3,
      },
      content4: {
        title: "โชห่วยยุค 4.0",
        detail:
          "มารู้จัก S-M-A-R-T model ที่ช่วยให้ร้านโชห่วยเติบโตแบบสมาร์ท ๆ",
        linkName: "ContentCard",
        paramsId: 4,
      }
    };
  },
  components: {
    CardDesign,
  },
  mounted() {
    this.$store.commit("setLessonID", "00" + this.$route.params.id);
    console.log(this.$store.getters.getLessonID);
  },
};
</script>